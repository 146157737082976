
export enum FinancialAccountTypes {
  SPENDING = 'spending',
  SAVINGS = 'savings',
  LOANS = 'loans',
}

export interface FinancialAccountUserInfo {
  canInstantAccountLink?: boolean;
  referenceId?: number;
  baasStatus?: string;
  kycStatus?: string;
  fraudStatus?: string;
  ofacStatus?: string;
  isLocked?: boolean;
  canLinkAccount?: boolean;
  canVerifyAccount?: boolean;
}

export interface FinancialAccountFundsSource {
  type: string,
  autoSaveSchedule: AutoSaveSchedule,
  nextAvailableDate: string
}

export interface FinancialAccountServiceability {
  status: ServiceabilityStatus,
  accessType: ServiceabilityAccessType,
  isLocked: boolean,
  lastModifiedDate?: Date,
  mustDelete: boolean;
  mustRepair: boolean;
}

export interface AutoSaveSchedule {
  status: AutoSaveScheduleStatus,
  nextDepositDate?: string,
  nextDepositAmount?: number,
  scheduleType?: string
}

export enum AutoSaveScheduleStatus {
  ACTIVE = 'active',
  NOTSTARTED = 'notStarted',
  PAUSED = 'paused',
  DISABLED = 'disabled'
}
export enum ServiceabilityStatus {
  OPEN = 'Open',
  INACTIVE = 'Inactive',
  DORMANT = 'Dormant',
  PENDINGOPEN = 'PendingOpen',
  PENDINGCLOSED = 'PendingClosed',
  CLOSED = 'Closed',
  UNVERIFIED = 'Unverified',
  VERIFYLOCKED = 'VerifyLocked'
}

export enum ServiceabilityAccessType {
  FULL = 'FULL',
  RDONLY = 'RDONLY',
  NONE = 'NONE'
}

export interface CoreAccountsInterface {
  availableBalance: number;
  accountId: number,
  accountDisplayName: string,
  accountBalance: number,
  accountNumberMasked: string,
  accountType: 'Savings' | 'Spending' | FinancialAccountTypes.SAVINGS | FinancialAccountTypes.SPENDING,
  fundsSource?: FinancialAccountFundsSource,
  serviceability?: FinancialAccountServiceability,
  isExternal: boolean,
  card?: NewSpendingCard,
}

export interface FinancialAccountTransactionsRequestConfig {
  offset: number,
  limit: number,
  lastTransactionId?: number|string
}

export interface NewSpendingCard {
  cardId: string,
  cardNumberMasked: string,
  status: CardStatus,
  expireMonth: number,
  expireYear: number,
  vendorTypeCode: string,
  createdDate: string,
  lockReasonTypeCode: string,
  lockTypeCode: string,
  verifiedDate: string
}

export interface FinancialAccountPayload<T> {
  payload: {
    user: FinancialAccountUserInfo;
  } & T;
}

export interface FinancialAccountTransactionsPayload {
  payload: FinancialAccountTransactionsResponse;
}

export interface FinancialAccountTransactionsResponse {
  transactions: FinancialAccountTransaction[];
  resultSet: TransactionsResultSet;
}

export interface FinancialAccountTransaction {
  transactionId: number;
  createdDate: string; // ISO 8601 date string
  amount: string;
  description: string;
  statusCopyKey: string;
  type: string;
  merchant: string;
}

export interface TransactionsResultSet {
  count: number;
  offset: number;
  limit: number;
  lastTransactionId: number;
  allFetched: boolean;
}

export interface TransactionPagination {
  pages: TransactionPaginationItem[]
}

export interface TransactionPaginationItem {
  page: number;
  transactions: FinancialAccountTransaction[]
}

export interface FinancialAccountDetailsPayload {
  payload: FinancialAccountDetails
}

export interface FinancialAccountDetails {
  accountId: string;
  accountNumber: string;
  routingNumber: string;
  bank: string;
  accountBalance: number;
  availableBalance: number;
  accountType: string;
  contactInfo: unknown;
}


export enum CardStatus {
  Verified = "VERIFIED",
  Denied = "DENIED",
  Pending = "PENDING",
  Expired = "EXPIRED",
  Archived = "ARCHIVED",
  HotListed = "HOT_LISTED",
  Reissued = "REISSUED",
  PendingVerification = "PENDING_VERIFICATION",
  ReissuedPendingVerification = "REISSUED_PENDING_VERIFICATION",
  Initiated = "INITIATED"
}

export interface AccountServiceLinks {
  labelKey: string;
  clickEvent: () => void;
  featureFlag?: string | string[] | undefined;
}
