import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";

@Directive({
  selector: '[brightsideWebFeatureFlag]'
})
export class NewFeatureFlagDirective {

  private hasView = false;
  private trueCount = 0;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagSvc: FeatureFlagService) {

  }

  @Input() set brightsideWebFeatureFlag(flag: string|string[]|undefined) {
    // if the flag is empty or falsy just display the content as default
    // someone accidentally sent a blank string for example
    if (!flag) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      const flagCheck: string[] = typeof flag === 'string' ? [flag] : flag;
      this.checkFlagsOfArray(flagCheck);
    }
  }

  private checkFlagsOfArray(flags: string[]) {
    this.featureFlagSvc.checkMultipleFlags(flags).subscribe(
      resp => {
        this.handleBoolResponse(resp);
      }
    );
  }

  private handleBoolResponse(show: boolean) {
    if (show) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
