<bw-billboard-article *ngIf="displayMe">
  <div class="media-small-center" >
    <typography display class="mb-smd blue-80">
      <div [innerHTML]="heading | translate"></div>
    </typography>
    <p text-medium-regular class="gray-50 mb-md" [innerHTML]="bodyCopy | translate:{customerNameFormatted:company}"></p>
    <div class="media-small-hidden mb-md">
      <typography title-medium>
        <div [innerHTML]="iconGroupTitle | translate"></div>
      </typography>
    </div>
  </div>
  <div class="flex-row media-small-hidden mb-md" style="flex-flow: row wrap;">
    <div class="mr-md">
      <div class="flex-row flex-start mb-sm" *ngIf="('BS_ICON_ONE' | translate) !== 'null'">
        <img src="../assets/{{iconPath.one}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_ONE' | translate"></p>
      </div>
      <div class="flex-row flex-start mb-sm" *ngIf="('BS_ICON_TWO' | translate) !== 'null'">
        <img src="../assets/{{iconPath.two}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_TWO' | translate"></p>
      </div>
      <div class="flex-row flex-start mb-sm" *ngIf="('BS_ICON_THREE' | translate) !== 'null'">
        <img src="../assets/{{iconPath.three}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_THREE' | translate"></p>
      </div>
      <div class="flex-row flex-start mb-sm" *ngIf="('BS_ICON_FOUR' | translate) !== 'null'">
        <img src="../assets/{{iconPath.four}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_FOUR' | translate"></p>
      </div>
      <div class="flex-row flex-start mb-sm" *ngIf="('BS_ICON_FIVE' | translate) !== 'null'">
        <img src="../assets/{{iconPath.five}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_FIVE' | translate"></p>
      </div>
      <div class="flex-row flex-start mb-sm" *ngIf="company.toLowerCase() !== 'amazon'">
        <img src="../assets/{{iconPath.six}}" class="mr-sm" alt="">
        <p text-medium-regular class="gray-50" [innerHTML]="'BS_ICON_SIX' | translate"></p>
      </div>
    </div>
  </div>
</bw-billboard-article>
