import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Host, HostListener,
  Input,
  OnDestroy,
  OnInit, Output,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { TitleCasePipe } from "@angular/common";
import { CompanyService } from "@brightside-web/desktop/data-access/shared";
import {Subscription} from "rxjs";

@Component({
  selector: 'brightside-web-how-we-help',
  templateUrl: './how-we-help.component.html',
  styleUrls: ['./how-we-help.component.scss'],
  providers: [TitleCasePipe]
})
export class HowWeHelpComponent implements OnInit, OnDestroy {

  private sub = new Subscription();

  company: string;
  displayMe = false;
  iconPath: {[key:string]:string};

  @Input() heading: string;
  @Input() bodyCopy: string;
  @Input() iconGroupTitle: string;
  @Output() introVideoClicked: EventEmitter<any> = new EventEmitter<any>()

  @HostListener('click', ['$event'])
  appOnClick(event: Event) {
    const target = <HTMLElement>event.target;
    if (target.nodeName === 'A' && target.classList.contains('showIntroVid')) {
      event.preventDefault();
      event.stopPropagation();
      this.introVideoClicked.emit(true);
    }
  }

  constructor(
    private titleCase: TitleCasePipe,
    private companyService: CompanyService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          this.company = this.titleCase.transform(value);
          if (value.toLowerCase() === 'amazon') {
            this.iconPath = {
              one: 'landing/amazon/calculator-amazon.svg',
              two: 'landing/amazon/financebills-amazon.svg',
              four: 'landing/amazon/financeincome-amazon.svg',
              five: 'landing/amazon/growth-amazon.svg',
              three: 'landing/amazon/piggybank-amazon.svg',
            }
          } else {
            this.iconPath = {
              one: 'landing/meijer/house.svg',
              two: 'landing/meijer/cash.svg',
              three: 'landing/meijer/card.svg',
              four: 'landing/meijer/piggy-bank.svg',
              five: 'landing/meijer/dollarsign.svg',
              six: 'landing/meijer/checkmarkbadge.svg',
            }
          }
          this.displayMe = true;
        }
      )
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
