<ng-container *ngTemplateOutlet="default"></ng-container>

<ng-template #default>
  <div class="action-card action-details-card flex-column space-between mb-lg">
    <div class="flex-column action-card__content">
      <ng-container *ngTemplateOutlet="title"></ng-container>
      <ng-container *ngTemplateOutlet="details"></ng-container>
      <typography text-small-regular color="var(--secondary-text)" class="action-card__body">{{ cardConfig.body }}</typography>
      <div class="flex-row space-between action-card_footer_action mt-sm">
        <div class="flex-column">
          <bw-icon *ngIf="cardConfig.optionalIcon" [iconName]="cardConfig.optionalIcon"></bw-icon>
        </div>
        <div class="flex-column">
          <div tabindex="1" class="action-card__cta_icon" (click)="emitCtaClicked()" (keyup.enter)="emitCtaClicked()">
            {{ cardConfig.ctaString }}
            <bw-icon [iconName]="'arrow_right'" color="var(--blue-50)" [size]="18"></bw-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #title>
  <div class="flex-row action-card__label mb-sm">
    <bw-icon *ngIf="cardConfig.labelIcon" [iconName]="cardConfig.labelIcon" [size]="32" [horizontalSpace]="'mr-xs'"></bw-icon>
    <div class="flex-item">
      <typography *ngIf="cardConfig.title" text-medium-semibold>{{ cardConfig.title }}</typography>
      <typography text-xsmall-regular color="var(--secondary-text)">{{ cardConfig.label }}</typography>
    </div>
  </div>
</ng-template>

<ng-template #details>
  <div class="action-details-card__details mb-sm pb-sm">
    <div *ngFor="let detail of cardConfig.details" class="flex-row space-between mb-xs">
      <typography text-xsmall-regular color="var(--secondary-text)">{{detail.title}}</typography>
      <typography text-xsmall-semibold>{{detail.detail}}</typography>
    </div>
  </div>
</ng-template>
